// extracted by mini-css-extract-plugin
export var devopsAchievementsSection = "J_gx";
export var devopsBenefitSection = "J_gm";
export var devopsBusinessSection = "J_gk";
export var devopsDomainSection = "J_gt";
export var devopsExpertiseSection = "J_gs";
export var devopsProvideSection = "J_gn";
export var devopsResultsSection = "J_gp";
export var devopsSuccessStoriesSection = "J_gv";
export var devopsTestimonialPostSection = "J_gq";
export var devopsToolkitSection = "J_gr";
export var devopsVideoReviewSection = "J_gy";
export var devopsWhyChooseSection = "J_gw";
export var devopsWhyNeedSection = "J_gl";